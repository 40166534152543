<!-- Preview Shipment-->
<p-progressBar mode="indeterminate" *ngIf="loading"></p-progressBar>
<!-- <p-messages severity="info" *ngIf="shipmentUpdatedNotification" [closable]="false" [showTransitionOptions]="'500ms'"
    [hideTransitionOptions]="'500ms'">
    <ng-template pTemplate>
        <span>{{ 'SHIPMENTPREVIEW.SHIPMENTUPDATED' | translate}}</span>
        <button iconPos="right" icon="pi pi-refresh" pButton pRipple label="{{'GENERIC.REFRESH' | translate}}"
            (click)="refreshShipment()"></button>
    </ng-template>
</p-messages> -->

<button pButton pRipple class="p-button-text p-button-rounded p-button-success mailMenuBtn" tooltipPosition="left"
  *ngIf="currentRoute == '/shipments/today' || currentRoute == '/archive'" icon="pi pi-envelope"
  [ngStyle]="currentRoute == '/shipments/today' && {'top': '70px', 'right': '60px'} || currentRoute == '/archive' && {'top': '6px', 'right': '35px'}"
  pTooltip="{{'OPENMAILMENU' | translate}}" (click)="openMailMenu(shipment)"></button>

<button pButton pRipple class="p-button-text p-button-rounded p-button-success mailMenuBtn" tooltipPosition="top"
  *ngIf="currentRoute == '/shipments/today' || currentRoute == '/archive' && (this.authService.hasPermission(hasPrintLabelPermission) || this.clientService.hasClientPermission(hasPrintLabelPermission))"
  icon="pi pi-copy"
  [ngStyle]="currentRoute == '/shipments/today' && {'top': '70px', 'right': '90px'} || currentRoute == '/archive' && {'top': '6px', 'right': '65px'}"
  pTooltip="{{'PRINTLABEL' | translate}}" (click)="printLabel()"></button>


<img *ngIf="shipment && shipment.carrier && shipment.carrier == 'PostNord' && !editMode"
  style="position: absolute;top:-35px;right:-10px; height:20px;" alt="PostNord"
  src="assets/layout/images/PostNord_wordmark.svg" />

<img *ngIf="shipment && shipment.carrier && shipment.carrier == 'GLS' && !editMode"
  style="position: absolute;top:-35px;right:-10px; height:20px;" alt="GLS" src="assets/layout/images/GLS_logo.png" />

<img *ngIf="shipment && shipment.carrier && shipment.carrier == 'Internal' && !editMode && tenantLogo"
  style="position: absolute;top:-35px;right:-10px; height:20px;" alt="Logo" [src]="tenantLogo" />

<div style="max-height:73vh; overflow-x:hidden; overflow-y:auto;" *ngIf="shipment && !editMode">
  <div *ngIf="shipment.omexInfo && shipment.omexInfo.omex">
    <div class="grid col" style="margin-bottom:-22px; margin-top:-22px;">
      <div class="md:col-2">
        <span><b>{{'OWNER' | translate}}</b></span>
      </div>
      <div class="md:col-2">
        <span><b>{{'OMEX' | translate}}</b></span>
      </div>
      <div class="md:col-2">
        <span><b>{{'ACCOUNT' | translate}}</b></span>
      </div>
      <div class="md:col-2">
        <span><b>{{'OMEXDATE' | translate}}</b></span>
      </div>
      <div class="md:col-2">
        <span><b>{{'OMEXDELIVERYDATE' | translate}}</b></span>
      </div>
    </div>

    <div class="grid col" style="margin-bottom:-10px;">
      <div class="md:col-2">
        <span>{{shipment.omexInfo.terminal}}</span>
      </div>
      <div class="md:col-2">
        <span>{{shipment.omexInfo.pickupTerminal}}<span
            style="color:var(--primary-color)"><b>></b></span>{{shipment.omexInfo.viaTerminal}}<span
            style="color:var(--primary-color)"><b>></b></span>{{shipment.omexInfo.deliveryTerminal}}</span>
      </div>
      <div class="md:col-2">
        <span>{{shipment.omexInfo.customerNo}}</span>
      </div>
      <div class="md:col-2">
        <span>{{shipment.omexInfo.date | date: 'dd-MM-yyyy'}}</span>
      </div>
      <div class="md:col-2">

      </div>
    </div>

    <p-divider layout="horizontal"></p-divider>

  </div>

  <div class="grid col" style="margin-bottom:-22px;">
    <div class="md:col-2">
      <span>{{'DATE' | translate}}</span>
    </div>
    <div class="md:col-3">
      <span>{{'REF1' | translate}}</span>
    </div>
    <div class="md:col-3">
      <span>{{'REF2' | translate}}</span>
    </div>
    <div class="md:col-2">
      <span>{{'REF3' | translate}}</span>
    </div>
    <div class="md:col-1">
      <span>{{'DEMANDS' | translate}}</span>
    </div>
    <div class="col-1 md:col-1" style="text-align-last: end;" *ngIf="editable && shipment.carrier && checkCollis()">
      <i style="cursor: pointer;" class="pi pi-pencil" tooltipPosition="left"
        pTooltip="{{'EDITREFERENCES' | translate}}" (click)="editRefs()"></i>
    </div>
  </div>
  <div class="grid col" style="padding-bottom:0px;">
    <div class="md:col-2">
      <span *ngIf="shipment.bookingDate">{{shipment.bookingDate | date : 'dd-MM-yyyy'}}</span>
    </div>
    <div class="md:col-3">
      <span>{{shipment.reference1}}</span>
    </div>
    <div class="md:col-3">
      <span>{{shipment.reference2}}</span>
    </div>
    <div class="md:col-2">
      <span>{{shipment.reference3}}</span>
    </div>
    <div class="md:col-1">
      <span>{{shipment.cashOnDelivery}}</span>
    </div>
  </div>

  <p-divider layout="horizontal" *ngIf="checkCollis()"></p-divider>


  <div class="grid col" style="margin-bottom:-16px; padding-top:0px;" *ngIf="checkCollis()">
    <div [ngClass]="{'md:col-3': editable, 'md:col-1': !editable}">
      <span style="color:var(--primary-color);">{{'COLLIEXCHANGE' | translate}}</span>
    </div>
    <div [ngClass]="{'md:col-3': editable, 'md:col-1': !editable}">
      <span>{{'FULLPALLETLABEL' | translate}}</span>
    </div>
    <div [ngClass]="{'md:col-3': editable, 'md:col-1': !editable}">
      <span>{{'HALFPALLETLABEL' | translate}}</span>
    </div>
    <div class="md:col-2">
      <span>{{'QUARTERPALLETLABEL' | translate}}</span>
    </div>
    <div class="col-1 md:col-1" style="text-align-last: end;" *ngIf="editable && checkCollis()">
      <i style="cursor: pointer;" class="pi pi-pencil" pTooltip="{{'EDITEXCHANGEPALLETS' | translate}}"
        tooltipPosition="left" (click)="editExchangePallets()"></i>
    </div>
    <div *ngIf="!editable" class="md:col-1">
      <span style="color:var(--primary-color);">{{'DEVIATIONS' | translate}}</span>
    </div>
    <div *ngIf="!editable" class="md:col-1">
      <span>{{'FULLPALLETLABEL' | translate}}</span>
    </div>
    <div *ngIf="!editable" class="md:col-1">
      <span>{{'HALFPALLETLABEL' | translate}}</span>
    </div>
    <div *ngIf="!editable" class="md:col-1">
      <span>{{'QUARTERPALLETLABEL' | translate}}</span>
    </div>
    <div *ngIf="!editable" class="md:col-2">
      <span>{{'DEVIATIONREASON' | translate}}</span>
    </div>

  </div>

  <div class="grid col" style="padding-bottom:0px;padding-top:0px; padding-left:16px;" *ngIf="checkCollis()">
    <div [ngClass]="{'md:col-3': editable, 'md:col-1': !editable}">
      <span *ngIf="shipment.fullExchangePallets?.quantity || shipment.halfExchangePallets?.quantity ||
        shipment.quarterExchangePallets?.quantity">{{'GENERIC.YES' | translate}}</span>
      <span *ngIf="!shipment.fullExchangePallets?.quantity && !shipment.halfExchangePallets?.quantity &&
                !shipment.quarterExchangePallets?.quantity">{{'GENERIC.NO' | translate}}</span>
    </div>
    <div [ngClass]="{'md:col-3': editable, 'md:col-1': !editable}">
      <span>{{shipment.fullExchangePallets?.quantity}}</span>
    </div>
    <div [ngClass]="{'md:col-3': editable, 'md:col-1': !editable}">
      <span>{{shipment.halfExchangePallets?.quantity}}</span>
    </div>
    <div class="md:col-2">
      <span>{{shipment.quarterExchangePallets?.quantity}}</span>
    </div>
    <div *ngIf="!editable" class="md:col-1">
      <span></span>
    </div>
    <div *ngIf="!editable" class="md:col-1">
      <span>{{shipment.fullExchangePalletsDeviation}}</span>
    </div>
    <div *ngIf="!editable" class="md:col-1">
      <span>{{shipment.halfExchangePalletsDeviation}}</span>
    </div>
    <div *ngIf="!editable" class="md:col-1">
      <span>{{shipment.quarterExchangePalletsDeviation}}</span>
    </div>
    <div *ngIf="!editable" class="md:col-2">
      <span>{{shipment.notExchangedPallets?.reason}}</span>
    </div>

  </div>

  <p-divider layout="horizontal"></p-divider>

  <div class="grid col gap-0" style="margin-top:-10px; padding-top:0px;">
    <div class="md:col-1">
      <span style="color:var(--primary-color);">{{'DATES' | translate}}</span>
    </div>
    <div class="md:col-2">
      <span>{{'PICKUPDATE' | translate}}</span>
    </div>
    <div class="md:col-3">
      <span>{{'PICKUPTIME' | translate}}</span>
    </div>
    <div class="md:col-2">
      <span>{{'DELIVERYDATEHEADER' | translate}}</span>
    </div>
    <div class="md:col-3">
      <span>{{'DELIVERYTIME' | translate}}</span>
    </div>

    <div class="col-1 md:col-1" style="text-align-last: end;" *ngIf="editable && checkCollis()">
      <i style="cursor: pointer;" class="pi pi-pencil" tooltipPosition="left" pTooltip="{{'EDITDATES' | translate}}"
        (click)="editDates()"></i>
    </div>

    <div class="col-1 md:col-1" style="text-align-last: end;"
      *ngIf="editable && shipment.carrier != 'Internal' && shipment.deliveryDate < today">
      <i style="cursor: pointer;" class="pi pi-refresh" tooltipPosition="left"
        pTooltip="{{'DELIVERYDATETODAY' | translate}}" (click)="setDeliveryDateToToday()"></i>
    </div>
  </div>

  <div class="grid col gap-0" style="margin-top:-28px; margin-bottom:-10px;">
    <div class="md:col-1">
      <span></span>
    </div>
    <div class="md:col-2">
      <span *ngIf="shipment.pickupDate">{{shipment.pickupDate | date : 'dd-MM-yyyy'}}</span>
      <!-- [ngStyle]="shipmentWithinDeadline(shipment) ? null : {'color': '#ff5555'}"-->
    </div>
    <div class="md:col-3">
      <span *ngIf="shipment.pickupTimeStart">{{shipment.pickupTimeStart.length == 8 ?
        shipment.pickupTimeStart.substring(0,5) :
        shipment.pickupTimeStart}}</span>

      <span *ngIf="shipment.pickupTimeEnd">-
        {{shipment.pickupTimeEnd.length == 8 ? shipment.pickupTimeEnd.substring(0,5) :
        shipment.pickupTimeEnd}}</span>
    </div>
    <div class="md:col-2">
      <span *ngIf="shipment.deliveryDate">{{shipment.deliveryDate | date : 'dd-MM-yyyy'}}</span>
    </div>
    <div class="md:col-3">
      <span *ngIf="shipment.deliveryTimeStart">{{shipment.deliveryTimeStart.length == 8 ?
        shipment.deliveryTimeStart.substring(0,5) :
        shipment.deliveryTimeStart}}</span>
      <span *ngIf="shipment.deliveryTimeEnd">- {{shipment.deliveryTimeEnd.length ==
        8 ? shipment.deliveryTimeEnd.substring(0, 5) : shipment.deliveryTimeEnd}}
      </span>
    </div>
  </div>

  <p-divider layout="horizontal"></p-divider>

  <div *ngIf="!shipment.isPickup" class="grid col" style="padding-top:0px; margin-top:-10px; margin-bottom:-18px;">
    <div class="md:col-6">
      <div class="col-12 md:col-12" style="display:flex; justify-content: space-between; padding:1px;">
        <span style="color: var(--primary-color);">{{'SENDER' | translate}}</span>
        <i *ngIf="editable && checkCollis()" style="cursor: pointer;" class="pi pi-pencil" tooltipPosition="left"
          pTooltip="{{'EDITSENDER' | translate}}" (click)="editSenderAddress()"></i>
      </div>
      <span>{{shipment?.senderAddress?.name}}</span><br />
      <span *ngIf="shipment?.senderAddress?.street">{{shipment?.senderAddress?.street}}<br /></span>
      <span>{{shipment?.senderAddress?.countryCode}}-{{shipment?.senderAddress?.zipCode}}
        {{shipment?.senderAddress?.city}}</span>
      <br />
      <span *ngIf="shipment?.senderAddress.contactPerson"> <br />{{shipment?.senderAddress.contactPerson}}
      </span>
      <span *ngIf="shipment?.senderAddress.contactPhone"><br />{{shipment?.senderAddress.contactPhone}}</span>
      <span *ngIf="shipment?.senderAddress.contactEmail"><br />{{shipment?.senderAddress.contactEmail}}</span>
    </div>

    <div class="md:col-6">
      <div class="col-12 md:col-12" style="display:flex; justify-content: space-between; padding:1px;">
        <span style="color: var(--primary-color);">{{'RECEIVER' | translate}}</span>
        <i *ngIf="editable && shipment.bookingType != 'PARCELSHOP'" style="cursor: pointer;" class="pi pi-pencil"
          tooltipPosition="left" pTooltip="{{'EDITRECEIVER' | translate}}" (click)="editRecipientAddress()"></i>
      </div>
      <span>{{shipment?.recipientAddress?.name}}</span><br />
      <span *ngIf="shipment?.recipientAddress?.street">{{shipment?.recipientAddress?.street}}<br /></span>
      <span>{{shipment?.recipientAddress?.countryCode}}-{{shipment?.recipientAddress?.zipCode}}
        {{shipment?.recipientAddress?.city}}</span>
      <br />
      <span *ngIf="shipment?.recipientAddress.contactPerson">
        <br />{{shipment?.recipientAddress.contactPerson}}
      </span>
      <span *ngIf="shipment?.recipientAddress.contactPhone"><br />{{shipment?.recipientAddress.contactPhone}}
      </span>
      <span *ngIf="shipment?.recipientAddress.contactEmail"><br />{{shipment?.recipientAddress.contactEmail}}</span>
    </div>
  </div>

  <p-divider layout="horizontal"></p-divider>

  <div class="grid col" style="padding-top:0px; padding-bottom:0px; margin-bottom:-10px; margin-top:-10px;">
    <div class="col-12 md:col-12">
      <div class="col-12 md:col-12" style="display:flex;justify-content: space-between; padding:0px;">
        <span style="color:var(--primary-color);">{{'SERVICE' | translate}}</span>
        <i *ngIf="editable && shipment.bookingType != 'PARCELSHOP' && shipment.bookingType != 'ABROAD'"
          style="cursor: pointer;" class="pi pi-pencil" pTooltip="{{'EDITSERVICES' | translate}}" tooltipPosition="left"
          (click)="editServices()"></i>
      </div>

      <div *ngIf="shipment.services && shipment.services?.length > 0">
        <div *ngFor="let service of shipment.services">
          <span>{{service.toUpperCase() | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <p-divider layout="horizontal"></p-divider>

  <div class="grid col" style="padding-top:0px; padding-bottom:0px; margin-bottom:-10px; margin-top:-10px;">
    <div class="col-12 md:col-12">
      <div class="col-12 md:col-12" style="display:flex;justify-content: space-between; padding:0px;">
        <span style="color:var(--primary-color);">{{'NOTICE' | translate}} </span>
        <i *ngIf="editable" style="cursor: pointer;" class="pi pi-pencil" pTooltip="{{'EDITNOTES' | translate}}"
          tooltipPosition="left" (click)="editNotes()"></i>
      </div>
      <span>{{shipment.note}}</span>
    </div>
  </div>

  <p-divider layout="horizontal"></p-divider>

  <div style="padding-top:0px; padding-bottom:0px; max-width:100%; overflow-x:auto;">
    <p-table [value]="shipment?.collis" [columns]="cols" class="tableFullWidth">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header | translate}}
          </th>
          <th *ngIf="editable && checkCollis()">
            <i style="cursor: pointer; color:var(--green-500);" class="pi pi-plus" pTooltip="{{'ADDCOLLI' | translate}}"
              tooltipPosition="left" (click)="editColli()"></i>
          </th>
          <th *ngIf="editable && checkCollis()">{{'REMOVE' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-colli>
        <tr class="tableRowBorderBottom">
          <td>{{colli.quantity != null ? colli.quantity : 1}}</td>
          <td>{{colli.type?.toUpperCase()}}</td>
          <td style="max-width:200px; overflow-y:hidden;overflow-x:auto;">
            <span *ngIf="colli.barcodes">
              <span *ngFor="let barcode of colli.barcodes">{{barcode}} <br /></span>
            </span>
          </td>
          <td style="width:400px;">{{colli.description}}</td>
          <td *ngIf="!editColliWeight">{{colli.weight > 0 ? colli.weight.toFixed(2) : colli.weight}}</td>
          <td *ngIf="editColliWeight">
            <p-inputNumber name="colliWeight" inputId="colliWeight" [(ngModel)]="colliToEdit.weight"
              [useGrouping]="false"></p-inputNumber>
          </td>
          <td>{{colli.volume > 0 ? colli.volume.toFixed(2) : colli.volume}}</td>
          <td>{{colli.length?.toFixed(2)}}</td>
          <td>{{colli.width?.toFixed(2)}}</td>
          <td>{{colli.height?.toFixed(2)}}</td>
          <td>{{colli.loadMeter?.toFixed(2)}}</td>
          <td *ngIf="editable && !editColliWeight"><i style="cursor:pointer;" class="pi pi-pencil"
              pTooltip="{{'EDITCOLLI' | translate}}" tooltipPosition="left" (click)="editColli(colli)"></i>
          <td *ngIf="editable && editColliWeight"><i style="cursor:pointer;" class="pi pi-save"
              pTooltip="{{'SAVECOLLI' | translate}}" tooltipPosition="left" (click)="saveColli(colli)"></i>
          </td>
          <td *ngIf="editable && editColliWeight"><i style="cursor:pointer;" class="pi pi-ban"
              pTooltip="{{'GENERIC.CANCEL' | translate}}" tooltipPosition="left" (click)="closeEdit()"></i>
          </td>
          <td *ngIf="editable && checkCollis()"><i style="cursor:pointer; color:var(--primary-color);"
              class="pi pi-trash" pTooltip="{{'DELETECOLLI' | translate}}" tooltipPosition="left"
              (click)="deleteColli(colli)"></i></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr style="border-top:1px solid grey;">
          <td>{{totalAmount()}}</td>

          <td>{{'TOTALS' | translate}}</td>

          <td colspan="2"></td>

          <td colspan="2"> <span *ngIf="totalWeight()">{{totalWeight()}}{{totalWeight() != null ? " kg" :
              ""}}</span></td>


          <td colspan="3"></td>

          <td colspan="3"><span>{{totalLoadmeter()?.toFixed(2)}}</span></td>

        </tr>
      </ng-template>
    </p-table>
  </div>

  <div>
    <div class="grid col">
      <div class="col-12 md:col-12" style="display:flex;justify-content: space-between; padding-bottom:0px;"
        *ngIf="checkCollis()">
        <span style="color:var(--primary-color);">{{'DANGEROUSGOODS' | translate}} </span>
        <i *ngIf="editable" style="cursor: pointer;" class="pi pi-pencil"
          pTooltip="{{'EDITDANGEROUSGOODS' | translate}}" tooltipPosition="left" (click)="editDangerousGoods()"></i>
      </div>

      <div *ngIf="shipment?.dangerousGoods && shipment?.dangerousGoods.length > 0">
        <div *ngFor="let adr of shipment?.dangerousGoods" class="col-12 md:col-12" style="padding:0px;">
          <span class="col-10 md:col-10">{{adr.waybillString.substring(0, 100)}}</span>
        </div>
      </div>
    </div>

    <p-divider *ngIf="checkCollis()"></p-divider>

  </div>
  <div>
    <div class="grid col">
      <div class="md:col-12" style="padding-top:0px;">
        <span style="color:var(--primary-color);">{{'TRACKANDTRACE' | translate}}:</span>
        <span style="color:darkgray;" *ngIf="shipment.trackAndTrace"> {{shipment.trackAndTrace}}</span>
      </div>


      <div *ngIf="chosenImage" class="md:col-4 col-4">
        <div style="background-color: white;">
          <img [src]="chosenImage" alt="billede" style="height:400px;" />
        </div>
      </div>

      <div [hidden]="!mapVisible" class="md:col-4 col-4">
        <div id="map" class="map" [leafletOptions]="scanMapOptions"></div>
      </div>


      <div class="md:col-12" *ngIf="shipment?.scans">
        <div class="md:col-12 col-12" *ngIf="shipment?.scans && shipment?.scans?.length > 0">
          <div style="border-bottom: 1px solid #a9a9a930;" class="md:col-12 col-12 grid"
            *ngFor="let scan of shipment?.scans">
            <span class="md:col-1 col-1">
              <i style="cursor: pointer;" *ngIf="scan.file" class="pi pi-camera" (click)="openImage(scan.file)"></i>

              <i style="cursor:pointer; padding-left:20px;" *ngIf="scan.image" class="pi pi-camera"
                (click)="openImage(scan.image)"></i>
            </span>

            <span *ngIf="scan.latitude || scan.longitude" class="md:col-3 cl-3 hoverText"
              pTooltip="{{'SHOWSCANSONMAP' | translate}}" (click)="showMap(scan)">{{scan.text}}</span>

            <span *ngIf="!scan.latitude || !scan.longitude" class="md:col-3 cl-3">{{scan.text}}</span>

            <span class="md:col-2 col-2"> {{scan.byTerminal}}/{{scan.driverId}}/{{scan.driverName}}</span>
            <span class="md:col-2 col-2" *ngIf="scan.scanDateTime">
              {{scan.scanDateTime | date : 'dd-MM-yyyy HH:mm'}}</span>
            <span class="md:col-4 col-4" *ngIf="scan.cause"> {{scan.cause}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="editMode">
  <!--  SenderAddress  -->
  <div class="md:col-12" *ngIf="senderAddressEdit">
    <app-address [header]="'SENDER' | translate" [address]="editObject" [removeBtns]="true"></app-address>

    <div class="saveBtn">
      <button pButton pRipple label="{{'GENERIC.BACK' | translate}}" (click)="closeEdit()"></button>
      <button pButton pRipple label="{{'GENERIC.SAVE' | translate}}" (click)="saveSenderAddress()"></button>
    </div>
  </div>

  <!-- RecipientAddress -->
  <div class="md:col-12" *ngIf="recipientAddressEdit">
    <app-address [header]="'RECEIVER' | translate" [address]="editObject" [removeBtns]="true"></app-address>

    <div class="saveBtn">
      <button pButton pRipple label="{{'GENERIC.BACK' | translate}}" (click)="closeEdit()"></button>
      <button pButton pRipple label="{{'GENERIC.SAVE' | translate}}" (click)="saveRecipientAddress()"></button>
    </div>
  </div>

  <!-- Dates -->
  <div class="col-12 md:col-12" *ngIf="datesEdit">
    <div>
      <h4 style="color:var(--primary-color);">{{'DATES' | translate}}</h4>

      <form [formGroup]="editDatesForm" class="grid gap-3">

        <div class="col-12 md:col-12" style="padding-bottom:0px; padding-top:0px;" *ngIf="checkCollis()">
          <p>{{'PICKUP' | translate}}</p>
        </div>

        <div class="col-3 md:col-3" *ngIf="checkCollis()">
          <span class="p-float-label">
            <p-inputMask formControlName="pickupDate" mask="99/99/9999" slotChar="dd/mm/yyyy"></p-inputMask>
            <label for="deliveryBefore">{{'GENERIC.THE' | translate}}</label>
          </span>
          <small style="color:#FC6161;" *ngIf="editDatesForm.hasError('PickupDate_LessThanToday')">{{'MUSTBEAFTERTODAY'
            | translate}}</small>
          <small style="color:#FC6161;" *ngIf="
            editDatesForm.hasError('PickupDate_LessThanOrEqualToToday')">{{'MUSTBETODAYORLATER' | translate}}</small>
        </div>

        <div style="align-self:center;" *ngIf="checkCollis()">
          <span class="p-float-label">
            <p-inputMask formControlName="pickupTimeStart" mask="99:99" slotChar="hh:mm"></p-inputMask>
            <label for="deliveryBefore">{{'FROMCLOCK' | translate}}</label>
          </span>
          <small style="color:#FC6161; position:fixed;"
            *ngIf="editDatesForm.hasError('PickupTimeStart_Invalid_Parsing')" class="p-invalid">{{'MAXIS' | translate}}
            '23:59'</small>
        </div>
        <div style="align-self:center;" *ngIf="checkCollis()">
          <span class="p-float-label">
            <p-inputMask formControlName="pickupTimeEnd" mask="99:99" slotChar="hh:mm"></p-inputMask>
            <label for="deliveryBefore">{{'TOCLOCK' | translate}}</label>
          </span>
          <small id="timeEndInvalid" style="color:#FC6161; position:fixed;" *ngIf="
            editDatesForm.hasError('invalidPickupTime')" class="p-invalid">
            {{'MUSTBEAFTERFROMCLOCK' | translate}}</small>

          <small style="color:#FC6161; position:fixed;" *ngIf="editDatesForm.hasError('PickupTimeEnd_Invalid_Parsing')"
            class="p-invalid">{{'MAXIS' | translate}}
            '23:59'</small>
        </div>


        <div class="col-12 md:col-12" style="padding-bottom:0px; padding-top:0px;">
          <p>{{'DELIVERY' | translate}}</p>
        </div>

        <div class="col-3 md:col-3">
          <span class="p-float-label">
            <p-inputMask formControlName="deliveryDate" mask="99/99/9999" slotChar="dd/mm/yyyy"></p-inputMask>
            <label for="deliveryBefore">{{'GENERIC.THE' | translate}}</label>
          </span>
          <small style="color:#FC6161;" *ngIf="editDatesForm.controls.deliveryDate.errors">{{'DELIVERYDATE' |
            translate}} {{'MUSTBEAFTERTODAY' | translate}}</small>
        </div>

        <div style="align-self:center;">
          <span class="p-float-label">
            <p-inputMask formControlName="deliveryTimeStart" mask="99:99" slotChar="hh:mm"></p-inputMask>
            <label for="deliveryBefore">{{'FROMCLOCK' | translate}}</label>
          </span>

          <small style="color:#FC6161; position:fixed;"
            *ngIf="editDatesForm.hasError('DeliveryTimeStart_Invalid_Parsing')" class="p-invalid">{{'MAXIS' |
            translate}}
            '23:59'</small>
        </div>

        <div style="align-self:center;">
          <span class="p-float-label">
            <p-inputMask formControlName="deliveryTimeEnd" mask="99:99" slotChar="hh:mm"></p-inputMask>
            <label for="deliveryBefore">{{'TOCLOCK' | translate}}</label>
          </span>
          <small style="color:#FC6161; position:fixed;" *ngIf="editDatesForm.hasError('invalidDeliveryTime')"
            id="deliveryEndInvalid" class="p-invalid">{{'MUSTBEAFTERFROMCLOCK' | translate}}</small>

          <small style="color:#FC6161; position:fixed;"
            *ngIf="editDatesForm.hasError('DeliveryTimeEnd_Invalid_Parsing')" class="p-invalid">{{'MAXIS' | translate}}
            '23:59'</small>

        </div>
      </form>
    </div>
    <p-divider></p-divider>
    <div class="saveBtn">
      <button pButton pRipple label="{{'GENERIC.BACK' | translate}}" (click)="closeEdit()"></button>
      <button [disabled]="!editDatesForm.valid" pButton pRipple label="{{'GENERIC.SAVE' | translate}}"
        (click)="saveDates()"></button>
    </div>
  </div>

  <!-- Colli -->
  <div class="col-12 md:col-12" *ngIf="colliEdit">
    <app-colli [collies]="editObject" [editColli]="colliToEdit" [removeBtns]="true"></app-colli>

    <div class="saveBtn" style="padding-top:10px;">
      <button pButton pRipple label="{{'GENERIC.BACK' | translate}}" (click)="closeEdit()"></button>
      <button pButton pRipple label="{{'GENERIC.SAVE' | translate}}" [disabled]="checkColliAgreement()"
        (click)="saveColli()"></button>
    </div>
  </div>

  <!--  Refs  -->
  <div class="col-12 md:col-12" *ngIf="refEdit">
    <h4 style="color:var(--primary-color);">{{'REFERENCES' | translate}}</h4>
    <div class="col-12 md:col-12 grid">
      <div class="col-4 md:col-4">
        <span class="p-float-label">
          <input style="width:100%;" pInputText [(ngModel)]="editObject.reference1" />
          <label>{{'REF1' | translate}}</label>
        </span>
      </div>
      <div class="md:col-4">
        <span class="p-float-label">
          <input [disabled]="editObject.accountId" input style="width:100%;" pInputText
            [(ngModel)]="editObject.reference2" />
          <label>{{'REF2' | translate}}</label>
        </span>
      </div>
      <div class="md:col-4">
        <span class="p-float-label">
          <input input style="width:100%;" pInputText [(ngModel)]="editObject.reference3" />
          <label>{{'REF3' | translate}}</label>
        </span>
      </div>
    </div>

    <p-divider></p-divider>
    <div class="saveBtn">
      <button pButton pRipple label="{{'GENERIC.BACK' | translate}}" (click)="closeEdit()"></button>
      <button pButton pRipple label="{{'GENERIC.SAVE' | translate}}" (click)="saveRefs()"></button>
    </div>
  </div>

  <!-- Exchange Pallets -->
  <div class="md:col-12" *ngIf="exchangePalletsEdit">
    <app-exchange-pallets [removeBtns]="true" [shipment]="editObject"></app-exchange-pallets>

    <p-divider></p-divider>
    <div class="saveBtn">
      <button pButton pRipple label="{{'GENERIC.BACK' | translate}}" (click)="closeEdit()"></button>
      <button pButton pRipple label="{{'GENERIC.SAVE' | translate}}" (click)="saveExchangePallets()"></button>
    </div>
  </div>

  <!-- Services -->
  <div class="col-12 md:col-12" *ngIf="servicesEdit">
    <h4 style="color:var(--primary-color);">{{'SERVICES' | translate}}</h4>

    <div class="p-fluid p-formgrid grid">
      <div *ngFor="let service of services" class="col-4 md:col-4">
        <p-checkbox [(ngModel)]="editObject.services" [value]="service.code"
          [label]="service.code.toUpperCase() | translate"></p-checkbox>
      </div>
    </div>

    <p-divider></p-divider>
    <div class="saveBtn">
      <button pButton pRipple label="{{'GENERIC.BACK' | translate}}" (click)="closeEdit()"></button>
      <button pButton pRipple label="{{'GENERIC.SAVE' | translate}}" (click)="saveServices()"></button>
    </div>
  </div>

  <!-- Single select Services ( GLS ) -->
  <div class="col-12 md:col-12" *ngIf="serviceSingleSelectEdit">
    <h4 style="color:var(--primary-color);">{{'SERVICES' | translate}}</h4>

    <div class="p-fluid p-formgrid grid">
      <div class="col-4 md:col-4">
        <p-radioButton [(ngModel)]="editObject.services" [value]="null"
          label="{{'NOEXTRASERVICES' | translate}}"></p-radioButton>
      </div>
      <div *ngFor="let service of services" class="col-4 md:col-4">
        <p-radioButton [(ngModel)]="editObject.services" [value]="service.code"
          [label]="service.code.toUpperCase() | translate"></p-radioButton>
      </div>
    </div>

    <p-divider></p-divider>
    <div class="saveBtn">
      <button pButton pRipple label="{{'GENERIC.BACK' | translate}}" (click)="closeEdit()"></button>
      <button pButton pRipple label="{{'GENERIC.SAVE' | translate}}" (click)="saveServices()"></button>
    </div>
  </div>


  <!-- Notes -->
  <div class="md:col-12" *ngIf="notesEdit">
    <h4 style="color:var(--primary-color);">{{'NOTE' | translate}}</h4>

    <div class="col-12 md:col-12">
      <textarea style="width:100%;max-width:100%;" pInputTextarea [(ngModel)]="editObject.note" rows="5"
        cols="30"></textarea>
    </div>

    <div class="col-12 md:col-12">
      <div class="saveBtn">
        <button pButton pRipple label="{{'GENERIC.BACK' | translate}}" (click)="closeEdit()"></button>
        <button pButton pRipple label="{{'GENERIC.SAVE' | translate}}" (click)="saveNotes()"></button>
      </div>
    </div>

  </div>


  <!-- Dangerous Goods -->
  <div class="col-12 md:col-12" *ngIf="dangerousGoodsEdit">
    <h4 style="color:var(--primary-color);">{{'DANGEROUSGOODS' | translate}}</h4>

    <div class="p-fluid p-formgrid grid">
      <div class="field-checkbox col-1 md:col-2" style="align-items: flex-start;">
        <p-checkbox name="colliAdr" id="adrGoods" [(ngModel)]="editObject.includeDangerousGoods"
          [binary]="true"></p-checkbox>
        <label for="colliAdr">{{'ADR' | translate}}</label>
      </div>

      <div class="col-12 md:col-10">
        <span class="p-float-label">
          <p-autoComplete [disabled]="!editObject.includeDangerousGoods" [suggestions]="foundAdrs" [minLength]="4"
            (completeMethod)="adrSearch($event)" [showEmptyMessage]="true" [(ngModel)]="editObject.dangerousGoods"
            emptyMessage="{{'NOADRFOUND' | translate}}" [multiple]="true" [field]="waybillShortString">
            <ng-template let-adr pTemplate="adr">
              <div class="adr-item">
                <span>{{adr.waybillString.substring(0, 8)}}</span><span
                  class="lightGrayText">{{adr.waybillString.substring(8, 80)}}</span>
              </div>
            </ng-template>

          </p-autoComplete>
          <label for="adr">{{'SEARCHADR' | translate}}</label>
        </span>
      </div>
    </div>

    <div class="col-12 md:col-12">
      <div class="saveBtn">
        <button pButton pRipple label="{{'GENERIC.BACK' | translate}}" (click)="closeEdit()"></button>
        <button pButton pRipple label="{{'GENERIC.SAVE' | translate}}" (click)="saveDangerousGoods()"></button>
      </div>
    </div>

  </div>
</div>

<p-confirmDialog key="deleteColliConfirm"></p-confirmDialog>
<p-confirmDialog key="setDeliveryDateToTodayConfirm"></p-confirmDialog>