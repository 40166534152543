<div>
    <form [formGroup]="payerForm">
        <div class="p-fluid p-formgrid grid gap-1">
            <div class="col-12 md:col-12" style="margin-bottom:-12px; padding-bottom:0px;">
                <h4 class="primColor">{{'PAYER' | translate}}</h4>
            </div>
            <div class="col-6 md:col-6" *ngIf="payers">
                <p-dropdown [options]="payers" formControlName="paymentChoice" inputId="payment" #payment
                    optionLabel="name" placeholder="Vælg betaler"
                    emptyMessage="{{'NORESULTS' | translate}}"></p-dropdown>
            </div>
        </div>
    </form>

    <p-divider></p-divider>

    <span style="font-size:small;" *ngIf="!shipment.clientPayerId">{{'PAYERREQUIRED' | translate}}</span>

    <div class="bottomBar">
        <button pButton pRipple label="{{'GENERIC.PREVIOUS' | translate}}" *ngIf="stepperService.activeIndex > 0"
            (click)="stepperService.stepPrev()"></button>
        <button [disabled]="!shipment.clientPayerId" pButton pRipple label="{{'GENERIC.NEXT' | translate}}"
            (focus)="stepperService.stepNext()" (click)="stepperService.stepNext()"></button>
    </div>
</div>