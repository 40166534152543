import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Shipment } from 'src/app/domain/Shipment';
import { ClientService } from 'src/app/services/client.service';
import { StepperService } from 'src/app/pages/Shared/stepper/stepper.service';

@Component({
  selector: 'app-service-info',
  templateUrl: './service-info.component.html',
  styleUrls: ['./service-info.component.scss']
})
export class ServiceInfoComponent implements OnInit {
  @Input() shipment = <Shipment>{};
  services: any;
  accounts: any;
  payments: any;

  serviceForm = new FormGroup({
    services: new FormControl(this.shipment.services)
  });

  constructor(public stepperService: StepperService, private clientService: ClientService) {
    this.shipment = this.stepperService.newShipment;
  }

  ngOnInit(): void {
    this.getServices();

  }

  getServices() {
    this.services = this.clientService.clientServices ?? [];
  }


}
