import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { StepperService } from './pages/Shared/stepper/stepper.service';
import { MenuItem } from 'primeng/api';
import { OBPermissions } from '@alicetms/auth.permissions';
import { AuthService } from './services/auth.service';
import { ShipmentService } from './services/shipment.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from './services/client.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  public model: MenuItem[] = [];

  showCustomerPermission = OBPermissions.ShowCustomers;
  showArchivePermission = OBPermissions.ShowArchive;
  showTodaysShipmentsPermission = OBPermissions.ShowTodaysShipments;
  showNotReadyPermission = OBPermissions.ShowNotReady;

  constructor(
    public app: AppComponent,
    public appMain: AppMainComponent,
    private router: Router,
    private stepperService: StepperService,
    private authService: AuthService,
    private shipmentService: ShipmentService,
    private translateService: TranslateService,
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.model.push({
      label: 'HEADERS.FRONTPAGE',
      icon: 'pi pi-home',
      routerLink: ['/'],
    });

    this.model.push({
      label: 'HEADERS.BOOKING',
      icon: 'pi pi-box',
      routerLink: ['/booking'],
      command: () => {
        this.stepperService.goToStart(true);
      },
    });

    // if (this.authService.hasPermission(this.showNotReadyPermission)) {
    const notReadyMenuItem = {
      label: 'HEADERS.CLEARANCE',
      icon: 'pi pi-fw pi-send',
      routerLink: ['/shipments'],
      badge: '',
    };
    this.shipmentService.getNotSubmittedShipments().subscribe((d) => (notReadyMenuItem.badge = d.length.toString()));
    this.model.push(notReadyMenuItem);
    // }

    // if (this.authService.hasPermission(this.showTodaysShipmentsPermission)) {
    const todaysOrdersMenuItem = {
      label: 'HEADERS.DAILYORDERS',
      icon: 'pi pi-fw pi-book',
      routerLink: ['/shipments/today'],
      badge: '',
    };
    this.shipmentService.getTodaysShipments().subscribe((d) => (todaysOrdersMenuItem.badge = d.length.toString()));
    this.model.push(todaysOrdersMenuItem);
    // }

    // if (this.authService.hasPermission(this.showArchivePermission)) {
    this.model.push({
      label: 'HEADERS.ARCHIVE',
      icon: 'pi pi-fw pi-inbox',
      routerLink: ['/archive'],
    });
    // }

    // if (this.authService.hasPermission(this.showCustomerPermission)) {
    this.model.push({
      label: 'HEADERS.CUSTOMERS',
      icon: 'pi pi-fw pi-users',
      routerLink: ['/customers'],
    });
    // }


    // if (this.authService.hasPermission(OBPermissions.UploadEDIFiles) || this.clientService.hasClientPermission(OBPermissions.UploadEDIFiles)) {
    //   this.model.push({ label: 'Indlæs EDI', icon: 'pi pi-fw pi-upload', routerLink: ['/edi'] });
    // }
  }
}
