<div class="p-fluid p-formgrid grid">
    <div class="col-6 md:col-6">
        <div class="card" style="position: relative;">
            <p-fileUpload #fileUpload name="edi[]" [multiple]="true" [customUpload]="true"
                (uploadHandler)="uploadFiles($event, fileUpload)" chooseLabel="{{'CHOOSEEDIFILES' | translate}}"
                advanced="true" (onSelect)="onEdiFileSelect($event)" (onClear)="clearFiles($event)"
                (onRemove)="fileRemoved($event)" uploadLabel="{{'UPLOADFILES' | translate}}"
                cancelLabel="{{'CLEARFILES' | translate}}">
            </p-fileUpload>
            <p style="position: absolute;top:30px; right:30px;" *ngIf="edi">{{'AMOUNTOFFILES' | translate}}:
                {{edi.length}}</p>
        </div>
    </div>

    <div class="col-3 md:col-3 col-offset-1">
        <div class="card">
            <h4 style="text-align:center; font-weight:500;">
                {{'UPLOADEDFILES' | translate}}</h4>
            <p-divider></p-divider>
            <ul class="col-12 md:col-12">
                <li style="list-style: none; display:flex; justify-content: space-between;"
                    *ngFor="let file of uploadedFiles">
                    <div *ngIf="file.objectUrl">
                        <p-image [src]="file.objectURL" alt="fil" width="50"></p-image>
                    </div>
                    <div *ngIf="!file.objectUrl">
                        <div [ngSwitch]="file.type">
                            <i *ngSwitchCase="'application/pdf'" class="pi pi-file-pdf"></i>

                            <i *ngSwitchCase="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                class="pi pi-file-pdf"></i>

                            <i *ngSwitchDefault class="pi pi-file"></i>
                        </div>
                    </div>
                    <p>{{file.name}}</p>
                    <p>{{file.size | number}} KB</p>
                </li>
            </ul>
        </div>
    </div>
</div>