import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EdiService {

    constructor(private httpClient: HttpClient) {
    }

    /**  Upload Files using Onlinebook Handler */
    uploadFiles(files: any[], handler: string = "onlinebook") {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });
        return this.httpClient.post(`${environment.apiUrl}/edi/upload?handler=${handler}`, formData);
    }
}

