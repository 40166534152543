import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StepperService } from '../stepper.service';
import { ClientService } from 'src/app/services/client.service';
import { Payer } from 'src/app/domain/Payer';

@Component({
  selector: 'app-payer',
  templateUrl: './payer.component.html',
  styleUrls: ['./payer.component.scss']
})
export class PayerComponent implements OnInit {
  shipment: any;
  payers: Payer[] = [];

  payerForm = new FormGroup({
    paymentChoice: new FormControl()
  });

  constructor(public stepperService: StepperService, private clientService: ClientService) {
    this.shipment = this.stepperService.newShipment;
    this.payers = this.clientService.clientPayers;
  }

  ngOnInit(): void {
    this.payerForm.valueChanges.subscribe((value) => {
      if (value.paymentChoice) {
        this.shipment.clientPayerId = value.paymentChoice.id;
      }
    });

    if (this.shipment.clientPayerId) {
      var payer = this.payers.find(x => x.id == this.shipment.clientPayerId);

      this.payerForm.controls.paymentChoice.setValue(payer, { emitEvent: false });
    }
  }

}
