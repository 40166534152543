import { Component, OnInit } from '@angular/core';
import { AppBreadcrumbService } from 'src/app/services/app.breadcrumb.service';
import { EdiService } from 'src/app/services/edi.service';

@Component({
  selector: 'app-edi',
  templateUrl: './edi.component.html',
  styleUrls: ['./edi.component.scss']
})
export class EdiComponent implements OnInit {
  uploadedFiles: any[] = [];
  edi: any[] = [];

  constructor(private breadcrumbService: AppBreadcrumbService, private ediService: EdiService) {
    this.breadcrumbService.setItems([
      { label: 'Upload Edi' },
    ]);
  }

  ngOnInit(): void {
  }

  uploadFiles(event: any, fileUpload) {
    if (this.edi && this.edi.length > 0) {
      this.ediService.uploadFiles(this.edi).subscribe(res => {
        if (res) {
          fileUpload.clear();
          this.edi = [];
        }
      });
    }
  }


  onEdiFileSelect(event: any) {
    for (let file of event.files) {
      if (!this.edi.find(x => x.name == file.name && x.size == file.size)) {
        this.edi.push(file);
      }
      else {
        // Was added
      }
    }
  }

  fileRemoved(event: any) {
    this.edi.splice(this.edi.indexOf(event.file), 1);
  }

  clearFiles(event: any) {
    this.edi = [];
  }

}
