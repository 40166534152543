<div>
  <form [formGroup]="serviceForm">
    <h4 class="primColor">{{'SERVICES' | translate}}</h4>
    <div class="p-fluid p-formgrid grid row-gap-1">

      <div *ngFor="let service of services" class="col-4 md:col-4">
        <p-checkbox [(ngModel)]="shipment.services" formControlName="services" [value]="service.code"
          [label]="service.name"></p-checkbox>
      </div>
    </div>
  </form>

  <p-divider></p-divider>

  <div class="bottomBar">
    <button pButton pRipple label="{{'GENERIC.PREVIOUS' | translate}}" *ngIf="stepperService.activeIndex > 0"
      (click)="stepperService.stepPrev()"></button>
    <button pButton pRipple label="{{'GENERIC.NEXT' | translate}}" (focus)="stepperService.stepNext()"
      (click)="stepperService.stepNext()"></button>
  </div>
</div>