import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxCSVParserError, NgxCsvParser } from 'ngx-csv-parser';
import { Observable, Subject } from 'rxjs';
import { CreateCustomer } from 'src/app/domain/Customer';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-import-customer-dialog',
  templateUrl: './import-customer-dialog.component.html',
  styleUrls: ['./import-customer-dialog.component.scss'],
})
export class ImportCustomerDialogComponent implements OnInit {
  constructor(private csvParser: NgxCsvParser, private customerService: CustomerService) {}

  hasHeader: boolean = false;
  csvRecords: Array<any> = [];

  delimiter: string = ',';

  columnDefinition = {
    customerNo: null,
    name: null,
    registrationNumber: null,
    street: null,
    zipCode: null,
    city: null,
    countryCode: null,
    contactName: null,
    contactPhone: null,
    contactEmail: null,
    note: null,
  };

  customers: CreateCustomer[] = [];

  status: any[] = [];

  @ViewChild('fileImportInput') fileImportInput: any;

  ngOnInit(): void {}

  fileChangeListener($event: any): void {
    console.log('Event', $event);
    const files = $event.files;
    this.csvParser
      .parse(files[0], {
        header: false,
        delimiter: this.delimiter,
        encoding: 'utf8',
      })
      .pipe()
      .subscribe({
        next: (result: Array<any>) => {
          this.csvRecords = result; //$.next(result);
          this.mapFields();
        },
        error: (error: NgxCSVParserError) => {
          console.error('Error', error);
        },
      });
  }

  mapFields() {
    this.customers = [];
    this.csvRecords.forEach((record) => {
      this.customers.push({
        customerNo: record[this.columnDefinition.customerNo],
        name: record[this.columnDefinition.name],
        registrationNumber: record[this.columnDefinition.registrationNumber],
        street: record[this.columnDefinition.street],
        zipCode: record[this.columnDefinition.zipCode],
        city: record[this.columnDefinition.city],
        countryCode: record[this.columnDefinition.countryCode],
        contactName: record[this.columnDefinition.contactName],
        contactPhone: record[this.columnDefinition.contactPhone],
        contactEmail: record[this.columnDefinition.contactEmail],
        note: record[this.columnDefinition.note],
        longitude: 0,
        latitude: 0,
      });
    });
  }

  onSubmit() {
    for (let i = 0; i < this.customers.length; i++) {
      const customer = this.customers[i];
      this.status[i] = 'pending';
      this.customerService.create(customer).subscribe({
        next: (res) => {
          this.status[i] = 'success';
        },
        error: (error) => {
          this.status[i] = 'error';
        },
      });
    }
  }

  getStatus(rowIndex) {
    return this.status[rowIndex] ?? 'pending';
  }
}
